@mixin wavebg($wave-pos, $wave-repeat, $wave-height, $wave-color) {
  background-image: inline-svg(
    '<svg width="255" height="14" viewBox="0 0 255 14" xmlns="http://www.w3.org/2000/svg"><path d="M168.995 9.223c-.16-.03-.323-.047-.49-.047-4.22 0-6.194-1.746-8.693-3.956C157.045 2.773 153.908 0 147.75 0c-6.158 0-9.294 2.773-12.062 5.22-2.498 2.21-4.472 3.956-8.69 3.956-4.217 0-6.19-1.746-8.69-3.956C115.54 2.773 112.404 0 106.248 0c-6.16 0-9.295 2.773-12.062 5.22-2.5 2.21-4.472 3.956-8.69 3.956-4.217 0-6.19-1.746-8.69-3.956C74.04 2.773 70.904 0 64.747 0c-6.158 0-9.293 2.773-12.06 5.22-2.498 2.21-4.472 3.956-8.688 3.956-4.218 0-6.19-1.746-8.69-3.956C32.54 2.773 29.405 0 23.248 0c-6.158 0-9.295 2.773-12.062 5.22-2.498 2.21-4.472 3.956-8.69 3.956C1.118 9.176 0 10.256 0 11.588 0 12.92 1.118 14 2.496 14c6.158 0 9.294-2.773 12.062-5.22 2.498-2.21 4.472-3.956 8.69-3.956 4.217 0 6.19 1.746 8.69 3.955C34.703 11.226 37.84 14 43.997 14c6.157 0 9.293-2.774 12.06-5.22 2.498-2.21 4.47-3.956 8.687-3.956 4.218 0 6.192 1.746 8.69 3.955 2.767 2.447 5.903 5.22 12.06 5.22 6.16 0 9.295-2.773 12.062-5.22 2.498-2.21 4.472-3.956 8.69-3.956 4.218 0 6.19 1.746 8.69 3.955 2.767 2.447 5.903 5.22 12.06 5.22 6.16 0 9.295-2.773 12.063-5.22 2.498-2.21 4.472-3.956 8.69-3.956 4.218 0 6.192 1.746 8.69 3.956 2.77 2.447 5.905 5.22 12.064 5.22.168 0 .332-.016.49-.047.16.03.322.047.49.047 6.125 0 9.245-2.773 11.998-5.22 2.485-2.21 4.45-3.956 8.644-3.956 4.196 0 6.16 1.746 8.644 3.955 2.753 2.447 5.873 5.22 11.998 5.22 6.125 0 9.245-2.774 11.997-5.22 2.485-2.21 4.448-3.956 8.643-3.956 4.196 0 6.16 1.746 8.644 3.955 2.753 2.447 8.092 5.22 11.998 5.22 3.906 0 3.96-4.824 0-4.824s-6.158-1.746-8.644-3.956C240.654 2.773 237.534 0 231.408 0c-6.125 0-9.244 2.773-11.997 5.22-2.484 2.21-4.447 3.956-8.642 3.956-4.195 0-6.158-1.746-8.644-3.956C199.372 2.773 196.252 0 190.126 0 184 0 180.88 2.773 178.128 5.22c-2.486 2.21-4.45 3.956-8.645 3.956-.167 0-.33.016-.488.047z" fill-rule="nonzero" fill="' +
      $wave-color + '"/></svg>'
  );
  background-size: auto $wave-height;
  background-repeat: $wave-repeat;
  background-position: $wave-pos;
}

@mixin nav-arrow-left($arrow-height, $arrow-color) {
  background-image: inline-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34"><g fill="#FEFEFE" fill-rule="evenodd"><path d="M3 17.513v.008C3.004 25.503 9.5 31.998 17.48 32c3.87 0 7.515-1.51 10.262-4.257C30.488 24.997 32 21.355 32 17.487v-.005C31.997 9.502 25.502 3.005 17.522 3c-3.866-.002-7.51 1.51-10.26 4.262C4.512 10.01 3 13.652 3 17.512m31-.602v.023C34 26.33 26.43 33.983 17.114 34c-4.582.008-8.88-1.746-12.105-4.938C1.782 25.868.003 21.602 0 17.05v-.015C0 7.652 7.592.01 16.93 0 26.33-.01 33.99 7.575 34 16.91"/><path d="M20.716 10.415c.295.35.367.828.184 1.247-.028.064-.058.123-.09.177-.143.233-.32.397-.464.53-.04.037-.08.073-.117.112-.8.816-1.568 1.604-2.357 2.415-.665.684-1.345 1.383-2.072 2.127l.116.123c1.41 1.423 3.07 3.108 4.712 4.824.1.106.183.233.243.376.204.484.07 1.04-.335 1.38-.4.336-.953.366-1.377.073-.057-.04-.11-.086-.162-.137-.94-.944-1.886-1.91-2.802-2.844-.882-.9-1.794-1.832-2.697-2.74-.334-.336-.497-.684-.497-1.064 0-.507.293-.867.548-1.124 1.158-1.166 2.327-2.362 3.458-3.52l1.26-1.287c.067-.07.133-.14.2-.213.19-.21.41-.45.687-.648.113-.08.242-.143.382-.182.433-.12.885.022 1.18.373"/></g></svg>'
  );
  background-size: auto $arrow-height;
  background-repeat: no-repeat;
  background-position: right top;
}
@mixin nav-arrow-right($arrow-height, $arrow-color) {
  background-image: inline-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34"><g fill="#FEFEFE" fill-rule="evenodd"><path d="M31 17.513v.008c-.004 7.982-6.5 14.477-14.48 14.48-3.87 0-7.515-1.51-10.262-4.257C3.512 24.997 2 21.355 2 17.487v-.005C2.003 9.502 8.498 3.005 16.478 3c3.866-.002 7.51 1.51 10.26 4.262C29.488 10.01 31 13.652 31 17.512M0 16.91v.023C0 26.33 7.57 33.983 16.886 34c4.582.008 8.88-1.746 12.105-4.938 3.227-3.194 5.006-7.46 5.01-12.012v-.015C34 7.652 26.408.01 17.07 0 7.67-.01.01 7.575 0 16.91"/><path d="M13.284 10.415c-.295.35-.367.828-.184 1.247.028.064.058.123.09.177.143.233.32.397.464.53.04.037.08.073.117.112.8.816 1.568 1.604 2.357 2.415.665.684 1.345 1.383 2.072 2.127l-.116.123c-1.41 1.423-3.07 3.108-4.712 4.824-.1.106-.183.233-.243.376-.204.484-.07 1.04.335 1.38.4.336.953.366 1.377.073.057-.04.11-.086.162-.137.94-.944 1.886-1.91 2.802-2.844.882-.9 1.794-1.832 2.697-2.74.334-.336.497-.684.497-1.064 0-.507-.293-.867-.548-1.124-1.158-1.166-2.327-2.362-3.458-3.52l-1.26-1.287c-.067-.07-.133-.14-.2-.213-.19-.21-.41-.45-.687-.648-.113-.08-.242-.143-.382-.182-.433-.12-.885.022-1.18.373"/></g></svg>'
  );
  background-size: auto $arrow-height;
  background-repeat: no-repeat;
  background-position: left top;
}

// functions to urlencode the svg string

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function url-encode($string) {
  $map: (
    "%": "%25",
    "<": "%3C",
    ">": "%3E",
    " ": "%20",
    "!": "%21",
    "*": "%2A",
    "'": "%27",
    '"': "%22",
    "(": "%28",
    ")": "%29",
    ";": "%3B",
    ":": "%3A",
    "@": "%40",
    "&": "%26",
    "=": "%3D",
    "+": "%2B",
    "$": "%24",
    ",": "%2C",
    "/": "%2F",
    "?": "%3F",
    "#": "%23",
    "[": "%5B",
    "]": "%5D"
  );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

@function inline-svg($string) {
  @return url('data:image/svg+xml;charset=US-ASCII,#{url-encode($string)}');
}
