.marvel-device{
	display: inline-block;
	position: relative;
	box-sizing: content-box !important;

	.screen {
		width: 100%;
		position: relative;
		height: 100%;
		z-index: 3;
		background: white;
		overflow: hidden;
		display: block;
		border-radius: 1px;
		box-shadow: 0 0 0 3px #111;
	}

	.top-bar, .bottom-bar {
		height: 3px;
		background: black;
		width: 100%;
		display: block;
	}

	.middle-bar {
		width: 3px;
		height: 4px;
		top: 0px;
		left: 90px;
		background: black;
		position: absolute;
	}

	&.iphone8{
		width: 375px;
		height: 667px;
		padding: 105px 24px;
		background: #d9dbdc;
		border-radius: 56px;
		box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.2);

		&:before{
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			position: absolute;
			top: 6px;
			content: '';
			left: 6px;
			border-radius: 50px;
			background: #f8f8f8;
			z-index: 1;
		}

		&:after{
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			position: absolute;
			top: 8px;
			content: '';
			left: 8px;
			border-radius: 48px;
			box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
			inset 0 0 6px 3px #FFFFFF;
			z-index: 2;
		}

		.home {
			border-radius: 100%;
			width: 68px;
			height: 68px;
			position: absolute;
			left: 50%;
			margin-left: -34px;
			bottom: 22px;
			z-index: 3;
			background: rgb(48,50,51);
			background: linear-gradient(135deg, rgba(48,50,51,1) 0%,rgba(181,183,185,1) 50%,rgba(240,242,242,1) 69%,rgba(48,50,51,1) 100%);

			&:before{
				background: #f8f8f8;
				position: absolute;
				content: '';
				border-radius: 100%;
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				top: 4px;
				left: 4px;
			}
		}

		.top-bar{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			top: 68px;
			left: 0;
		}

		.bottom-bar{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			bottom: 68px;
			left: 0;
		}

		.sleep{
			position: absolute;
			top: 190px;
			right: -4px;
			width: 4px;
			height: 66px;
			border-radius: 0px 2px 2px 0px;
			background: #d9dbdc;
		}

		.volume{
			position: absolute;
			left: -4px;
			top: 188px;
			z-index: 0;
			height: 66px;
			width: 4px;
			border-radius: 2px 0px 0px 2px;
			background: #d9dbdc;

			&:before {
				position: absolute;
				left: 2px;
				top: -78px;
				height: 40px;
				width: 2px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}

			&:after {
				position: absolute;
				left: 0px;
				top: 82px;
				height: 66px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}
		}

		.camera {
			background: #3c3d3d;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 24px;
			left: 50%;
			margin-left: -6px;
			border-radius: 100%;
			z-index: 3;
		}

		.sensor {
			background: #3c3d3d;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 49px;
			left: 134px;
			z-index: 3;
			border-radius: 100%;
		}

		.speaker {
			background: #292728;
			width: 70px;
			height: 6px;
			position: absolute;
			top: 54px;
			left: 50%;
			margin-left: -35px;
			border-radius: 6px;
			z-index: 3;
		}

		&.gold{
			background: #f9e7d3;

			.top-bar, .bottom-bar{
				background: white;
			}

			.sleep, .volume{
				background: #f9e7d3;
			}

			.home{
				background: rgb(206,187,169);
				background: linear-gradient(135deg, rgba(206,187,169,1) 0%,rgba(249,231,211,1) 50%,rgba(206,187,169,1) 100%);
			}
		}

		&.black{
			background: #464646;
			box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.7);

			&:before{
				background: #080808;
			}

			&:after{
				box-shadow:
					inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
					inset 0 0 6px 3px #212121;
			}

			.top-bar, .bottom-bar{
				background: #212121;
			}

			.volume, .sleep{
				background: #464646;
			}

			.camera{
				background: #080808;
			}

			.home{
				background: rgb(8,8,8);
				background: linear-gradient(135deg, rgba(8,8,8,1) 0%,rgba(70,70,70,1) 50%,rgba(8,8,8,1) 100%);

				&:before{
					background: #080808;
				}
			}

		}

		&.landscape{
			padding: 24px 105px;
			height: 375px;
			width: 667px;

			.sleep{
				top: 100%;
				border-radius: 0px 0px 2px 2px;
				right: 190px;
				height: 4px;
				width: 66px;
			}

			.volume {
				width: 66px;
				height: 4px;
				top: -4px;
				left: calc(100% - 188px - 66px);
				border-radius: 2px 2px 0px 0px;

				&:before {
					width: 40px;
					height: 2px;
					top: 2px;
					right: -78px;
					left: auto;
					border-radius: 2px 2px 0px 0px;
				}

				&:after{
					left: -82px;
					width: 66px;
					height: 4px;
					top: 0;
					border-radius: 2px 2px 0px 0px;
				}
			}

			.top-bar{
				width: 14px;
				height: 100%;
				left: calc(100% - 68px -  14px);
				top: 0;
			}

			.bottom-bar{
				width: 14px;
				height: 100%;
				left: 68px;
				top: 0;
			}

			.home{
				top: 50%;
				margin-top: -34px;
				margin-left: 0;
				left: 22px;
			}

			.sensor {
				top: 134px;
				left: calc(100% - 49px - 16px);
			}

			.speaker {
				height: 70px;
				width: 6px;
				left: calc(100% - 54px - 6px);
				top: 50%;
				margin-left: 0px;
				margin-top: -35px;
			}

			.camera {
				left: calc(100% - 32px);
				top: 50%;
				margin-left: 0px;
				margin-top: -5px;
			}
		}
	}

	&.iphone8plus{
		width: 414px;
		height: 736px;
		padding: 112px 26px;
		background: #d9dbdc;
		border-radius: 56px;
		box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.2);

		&:before{
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			position: absolute;
			top: 6px;
			content: '';
			left: 6px;
			border-radius: 50px;
			background: #f8f8f8;
			z-index: 1;
		}

		&:after{
			width: calc(100% - 16px);
			height: calc(100% - 16px);
			position: absolute;
			top: 8px;
			content: '';
			left: 8px;
			border-radius: 48px;
			box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
				inset 0 0 6px 3px #FFFFFF;
			z-index: 2;
		}

		.home {
			border-radius: 100%;
			width: 68px;
			height: 68px;
			position: absolute;
			left: 50%;
			margin-left: -34px;
			bottom: 24px;
			z-index: 3;
			background: rgb(48,50,51);
			background: linear-gradient(135deg, rgba(48,50,51,1) 0%,rgba(181,183,185,1) 50%,rgba(240,242,242,1) 69%,rgba(48,50,51,1) 100%);

			&:before{
				background: #f8f8f8;
				position: absolute;
				content: '';
				border-radius: 100%;
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				top: 4px;
				left: 4px;
			}
		}

		.top-bar{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			top: 68px;
			left: 0;
		}

		.bottom-bar{
			height: 14px;
			background: #bfbfc0;
			position: absolute;
			bottom: 68px;
			left: 0;
		}

		.sleep{
			position: absolute;
			top: 190px;
			right: -4px;
			width: 4px;
			height: 66px;
			border-radius: 0px 2px 2px 0px;
			background: #d9dbdc;
		}

		.volume{
			position: absolute;
			left: -4px;
			top: 188px;
			z-index: 0;
			height: 66px;
			width: 4px;
			border-radius: 2px 0px 0px 2px;
			background: #d9dbdc;

			&:before {
				position: absolute;
				left: 2px;
				top: -78px;
				height: 40px;
				width: 2px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}

			&:after {
				position: absolute;
				left: 0px;
				top: 82px;
				height: 66px;
				width: 4px;
				border-radius: 2px 0px 0px 2px;
				background: inherit;
				content: '';
				display: block;
			}
		}

		.camera {
			background: #3c3d3d;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 29px;
			left: 50%;
			margin-left: -6px;
			border-radius: 100%;
			z-index: 3;
		}

		.sensor {
			background: #3c3d3d;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 54px;
			left: 154px;
			z-index: 3;
			border-radius: 100%;
		}

		.speaker {
			background: #292728;
			width: 70px;
			height: 6px;
			position: absolute;
			top: 59px;
			left: 50%;
			margin-left: -35px;
			border-radius: 6px;
			z-index: 3;
		}

		&.gold{
			background: #f9e7d3;

			.top-bar, .bottom-bar{
				background: white;
			}

			.sleep, .volume{
				background: #f9e7d3;
			}

			.home{
				background: rgb(206,187,169);
				background: linear-gradient(135deg, rgba(206,187,169,1) 0%,rgba(249,231,211,1) 50%,rgba(206,187,169,1) 100%);
			}
		}

		&.black{
			background: #464646;
			box-shadow:inset 0 0 3px 0 rgba(0, 0, 0, 0.7);

			&:before{
				background: #080808;
			}

			&:after{
				box-shadow:
					inset 0 0 3px 0 rgba(0, 0, 0, 0.1),
					inset 0 0 6px 3px #212121;
			}

			.top-bar, .bottom-bar{
				background: #212121;
			}

			.volume, .sleep{
				background: #464646;
			}

			.camera{
				background: #080808;
			}

			.home{
				background: rgb(8,8,8);
				background: linear-gradient(135deg, rgba(8,8,8,1) 0%,rgba(70,70,70,1) 50%,rgba(8,8,8,1) 100%);

				&:before{
					background: #080808;
				}
			}

		}

		&.landscape{
			padding: 26px 112px;
			height: 414px;
			width: 736px;

			.sleep{
				top: 100%;
				border-radius: 0px 0px 2px 2px;
				right: 190px;
				height: 4px;
				width: 66px;
			}

			.volume {
				width: 66px;
				height: 4px;
				top: -4px;
				left: calc(100% - 188px - 66px);
				border-radius: 2px 2px 0px 0px;

				&:before {
					width: 40px;
					height: 2px;
					top: 2px;
					right: -78px;
					left: auto;
					border-radius: 2px 2px 0px 0px;
				}

				&:after{
					left: -82px;
					width: 66px;
					height: 4px;
					top: 0;
					border-radius: 2px 2px 0px 0px;
				}
			}

			.top-bar{
				width: 14px;
				height: 100%;
				left: calc(100% - 68px -  14px);
				top: 0;
			}

			.bottom-bar{
				width: 14px;
				height: 100%;
				left: 68px;
				top: 0;
			}

			.home{
				top: 50%;
				margin-top: -34px;
				margin-left: 0;
				left: 24px;
			}

			.sensor {
				top: 154px;
				left: calc(100% - 54px - 16px);
			}

			.speaker {
				height: 70px;
				width: 6px;
				left: calc(100% - 59px - 6px);
				top: 50%;
				margin-left: 0px;
				margin-top: -35px;
			}

			.camera {
				left: calc(100% - 29px);
				top: 50%;
				margin-left: 0px;
				margin-top: -5px;
			}
		}
	}
}