.main-footer {
	background:$brand-primary;
	@include gradient-directional($brand-primary, #4E84E0, 45deg);
	color:#FFF;
	padding:30px 0;
	a {
		color:#FFF;
		text-decoration:underline;
		&.btn {
			text-decoration:none;
		}
		&.termeni {
			margin-top:40px;
		}
	}
	.organiser-logos {
		max-width:400px;
		margin:60px auto;
	}
	.organiser-text {
		h3 {
			font-size:24px;
			line-height:1.5;
			margin-bottom:60px;
		}
	}
	p.copy {
		margin-bottom:40px;
	}
}
#termeni-inline {
	.inner {
		padding:30px;
		max-width:80%;
		overflow:auto;
		max-height:80vh;
		margin:60px auto;
		background:#FFF;
		h2 {
			margin-bottom:30px;
			margin-top:0;
		}
	}
}