.section-header {
	width:100%;
	display:block;
	position:relative;
	text-align:center;
	&:before {
		content:' ';
		position:absolute;
		z-index:1;
		width:100%;
		height:4px;
		@include gradient-horizontal-three-colors($brand-primary, #4E84E0,50%, $brand-primary );
		top:50%;
		left:0;
		margin-top:-2px;
	}
	h1, h2 {
		text-transform:uppercase;
		position:relative;
		display:inline-block;
		text-align:center;
		background:#FFF;
		padding:10px 30px;
		font-size:48px;
		font-weight:300;
		z-index:2;
	}
	h2 {
		font-size:32px;
	}
}
section#section-video {
	position:relative;
	z-index:1;
	// background:url(../images/video_placeholder.jpg) center top no-repeat #000;
	background-size:cover;
	video {
	  width: 100%    !important;
	  height: auto   !important;
	  position:relative;
	  z-index:1;
	}
	.video_overlay {
		position:absolute;
		width:100%;
		height:100%;
		color:#FFF;
		z-index:3;
		top:-6px;
		.video_button {
			display:none;
			position:absolute;
			width:94px;
			height:94px;
			top:50%;
			left:50%;
			margin-left:-47px;
			margin-top:-47px;
			background:url(../images/video_controls.png) center bottom no-repeat;
			&.paused {
				background-position:center top;
			}
		}
		&:hover {
			background:rgba(0,0,0,0.4);
			.video_button {
				display:block;
			}
		}

	}
}

section#people {
	margin-top:-6px;
	.section-image {
		img {
			margin:0 auto;
			width:100%;
			height:auto;
		}
	}
}
section#logo {
	margin-top:-60px;
	.via-logo {
		img {
			margin:0 auto;
			max-height:90px;
		}

	}
}
section#onboarding {
	padding:60px 0px;
	.phone-screen {
		text-align:center;
		margin-bottom:60px;
		img {
			margin:0 auto;
		}

		h3 {
			margin-top:40px;
			font-size:24px;
			font-weight:400;
			text-align:center;
		}
	}
}
section#tinericucancer {
	position:relative;
	.testimonial-trigger {
	    font-size: 14px;
	    padding: 10px 40px;
	    position: absolute;
	    left: 50%;
	    bottom: 15%;
	    max-width: 340px;
	    width: 300px;
    	margin-left: -150px;
    	z-index: 99;
	}
	.section-header {
		&:before {
			display:none;
		}
		h2 {

			color:#FFF;
			text-align:center;
			font-weight:300;
			margin:20px auto;
			background:none;
			padding:0;
		}

		@include gradient-directional($brand-primary, #4E84E0, 45deg);
	}
	img {
		margin:0 auto;
		width:100%;
		height:auto;
	}

}
section#descarca {
	background:url(../images/section_image3.jpg) right top no-repeat;
	background-size:cover;
	position:relative;
	margin-top: 30px;
	&:before {
		content: ' ';
		display:none;
		position:absolute;
		height:100px;
		@include gradient-vertical(#FFF, transparent);
		width:100%;
	}
	.section-header {
		margin-top:-30px;
		h2 {
			background:transparent;
			margin-top:0;
		}
		&:before {
			display:none;
		}
	}
	.download-zone {
		margin-bottom:80px;
		margin-top:40px;
		.via-logo-white {
			text-align:center;
			img {
				margin:0 auto;
			}
		}
		.download-buttons {
			a {
				display:inline-block;
				width:49%;
			}
		}
	}
}

.lity-opened {
	.testimonial-image {
		float:right;
		max-width:260px;
		margin-left:30px;
		margin-bottom:30px;
		width:100%;
		border-radius:50%;
		height:260px;
		img {
			border-radius:100%;
		}
	}
}

/**
* MEDIA QUERIES
* 3 breakpoints
*/
@media (min-width: $screen-sm-min) {
	section#tinericucancer {
		position:relative;
		.testimonial-trigger {
		    left: 50%;
		    bottom: 15%;
		    max-width: 330px;
		    margin-left: -165px;
		    z-index: 99;
		}
	}
}

@media (min-width: $screen-md-min) {
	section#tinericucancer {
		position:relative;
		overflow: hidden;
		.testimonial-trigger {
		    font-size: 18px;
		    padding: 20px 60px;
		    position: absolute;
		    left: 50%;
		    bottom: 15%;
		    max-width: 370px;
		    width:100%;
	    	margin-left: -185px;
	    	z-index: 99;
		}
		#gallery-1 {
			overflow: hidden;
		}
	}
	section#onboarding {
		padding:120px 0px;
		.phone-screen {
			&.phone-left {
				float:left;
			}
			&.phone-right {
				float:right;
			}
			h3 {
				margin-top:40px;
				font-size:34px;
			}
		}
	}
	.section-header {
		h1 {
			padding:10px 30px;
			font-size:70px;
		}
		h2 {
			padding:10px 30px;
			font-size:48px;
		}
	}
	section#logo {
		margin-top:-30px;
		.via-logo {
			img {
				margin:0 auto;
				max-height:initial;
			}

		}
	}
	section#descarca {
		margin-top: 60px;
		&:before {
			display:none;
		}
		.section-header {
			margin-top:-60px;
			h2 {
				margin-top: 20px;
				background:#FFF;
			}
		}
		.download-zone {
			margin-bottom:160px;
			margin-top:120px;
		}
	}
}

@media (min-width: $screen-lg-min) {

}

