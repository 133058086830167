// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #39BBEE;
$brand-secondary: 		#F3CB00;

$font-family-sans-serif: 'Fira Sans', sans-serif !default;