.btn {
	&.btn-primary {
		@include gradient-horizontal(#FFE561, $brand-secondary);
		border:none;
		font-size:15px;
		color:#000;
		font-weight:500;
		box-shadow: 0 1px 5px 0px rgba(0,0,0,0.3);
		padding:13px 30px;
		&:hover {
			box-shadow: 0 2px 7px 0px rgba(0,0,0,0.3);
		}
		&:focus, &:active {
			box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.3);
			color:#000;
		}
	}
}